import dmshttp from "@/utils/dmsrequest";

// 销售出库单明细查询
export function getOrderOutList(data) {
    return dmshttp({
        url: "distributorReturnOrderDetail",
        data
    });
}



// 获取出库表格信息
export function getTableList(data) {
    return dmshttp({
        url: 'findInventoryProductList',
        data
    })
}

// 点击出库
export function saleOrderOut(data) {
    return dmshttp({
        url: 'distributorReturnOrderOutbound',
        data
    })
}
// 判断是否启用补差类型
export function difference(data) {
    return dmshttp({
        url: 'isEnableOrNot',
        data
    })
}