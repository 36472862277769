<template>
  <div class="wrap">
    <div class="pur-top-right-icon">
      <i
        class="iconfont icon-dailishangfaqituihuo"
        :class="'i-green'"
        v-if="!CANCEL || this.statusObj.UNCHKED"
      />
      <span
        :class="[currentIndex > 1 ? 'green' : 'gray']"
        v-if="(!CANCEL || this.statusObj.UNCHKED) && isDistributorReturns !=='1'"
      ></span>
      <i
        v-if="(!CANCEL || this.statusObj.REVIEWCOMPLETED) && isDistributorReturns !=='1'"
        class="iconfont icon-fenxiaoshangtuihuoruku"
        :class="[currentIndex >= 2 ? 'i-green' : 'i-gray']"
      />
      <span
        :class="[currentIndex > 2 ? 'green' : 'gray']"
        v-if="!CANCEL || this.statusObj.REVIEWCOMPLETED"
      ></span>
      
      <i
        v-if="!CANCEL || this.statusObj.WAITDELIVER"
        class="iconfont icon-dailishangtuihuoruku"
        :class="[currentIndex >= 3 ? 'i-green' : 'i-gray']"
      />
     
      
    </div>
    <div class="pur-top-right-txt">
      <div v-if="!CANCEL || this.statusObj.UNCHKED">
        <p style="padding-right:4px">{{isDistributorReturns=='1'?'分销商发起退货':'代理商发起退货'}}</p>
        <!--        <p v-if="currentIndex >= 1">{{ this.statusObj.UNCHKED }}</p>-->
      </div>
     
      <div  v-if="(!CANCEL || this.statusObj.WAITDELIVER) && isDistributorReturns !=='1'">
        <p>分销商退货出库</p>
        <!--        <p v-if="currentIndex >= 3">{{ this.statusObj.WAITDELIVER }}</p>-->
      </div>
      <div v-if="!CANCEL || this.statusObj.PARTCHECKED">
        <p>代理商退货入库</p>
        <!--        <p v-if="currentIndex >= 4">{{ this.statusObj.PARTCHECKED }}</p>-->
      </div>
     
    </div>
  </div>
</template>

<script>
export default {
  name: "Step",
  props: {
    status: {
      type: String
    },
    isDistributorReturns: {
      type: String
    },
  },
  computed: {
    currentIndex: function() {
      let step = 1;
      if (this.status) {
        const status = this.status;
        if (status == "submitted") {
          step = 2;
        }
        if (status == "partially-operate") {
          step = 2;
        }
      
        if (status == "part_shipped") {
          step = 2;
        }
       
        if (status == "confirmed") {
          step = 2;
        }
        if (status == "partially-confirm") {
          step = 2;
        }
        if (status == "examined") {
          step = 2;
        }
        if (status == "refused") {
          step = 2;
        }
        if (status == "disagreed") {
          step = 2;
        }
        if (status == "finished") {
          step = 3;
        }
        if (status == "unoperate") {
          step = 3;
        }
        if (status == "unoperate") {
          step = 4;
        }

        if (status == "operated") {
          step = 5;
        }

        return step;
      } else {
        return step;
      }
    }
  },
  watch:{
    
    status:function (){
      const status = this.status
      if (status == "confirmed") {
        this.secondStepName = "评审完成";
      }
      if (status == "partially-confirm") {
        this.secondStepName = "部分确认";
      }
      if (status == "examined") {
        this.secondStepName = "已审核";
      }
      if (status == "refused") {
        this.secondStepName = "已拒绝";
      }
      if (status == "disagreed") {
        this.secondStepName = "已拒绝";
      }
    }
  },
  data() {
    return {
      secondStepName: "订单评审",
      statusObj: {},
      CANCEL: false
    };
  },
  methods:{

  }
};
</script>

<style scoped lang="less">
.wrap {
  width: 930px;
  height: 139px;
  background: #fff;

  .pur-top-right-icon {
    text-align: left;
    padding-left: 70px;

    .iconfont {
      border-radius: 50%;
      width: 35px;
      height: 35px;
      line-height: 35px;
      font-size: 21px;
      position: relative;
      top: 8px;
      text-align: center;
    }

    .i-green {
      color: #00aaa6;
      border: 1.5px solid #00aaa6;
    }

    .i-gray {
      color: #aaa;
      border: 1.5px solid #aaa;
    }

    img {
      width: 36px;
      height: 36px;
    }

    span {
      width: 142px;
      margin: 18px 6px 0;
    }

    .green {
      border-bottom: 1px dashed #00aaa6;
      width: 354px;
    }

    .gray {
      border-bottom: 1px dashed #cccccc;
        width: 354px;
    }
  }

  .pur-top-right-txt {
   div {
      p{
        padding-top: 8px;
      }
    }
    margin: 12px 0 0 44px;
    div:first-child{
      margin-right: 312px;
    }
    div:nth-child(2){
      margin-right: 318px;
    }
    display: flex;
    // justify-content: space-between;
    :nth-child(5) {
      margin-right: 0px;
    }

    div {
      // float: left;
      // width: 120px;
      // margin: 0 70px 0 0;

      p {
        font-size: 12px;
        font-weight: 500;
        color: #262626;
        line-height: 12px;
        margin-bottom: 7px;
      }
    }
  }
}
</style>
